import { Button } from 'shards-react'
import React from 'react'
import addFont from '../../../../fonts/fonts'
import jsPDF from 'jspdf'
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import styles from '../styles.module.css'



const orientation = 'l'
const fileTitle = `Отчет о поступлении выпускников`

export default function Buttons ({ visible, year }) {

  const getXLSdata = () => {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push("page1");
    const table = document.getElementById("graduatesTable")
    console.log(table)
    const ws = XLSX.utils.table_to_sheet(table);
    wb.Sheets["page1"] = ws;
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    const s2ab = (s) => {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `${fileTitle}.xlsx`);
  }

  const getPdfData = async () => {
    
    addFont(jsPDF.API)
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

    const {width, height} = doc.internal.pageSize
    let lastY = 10

    const getY = (indent, newValue) => {
      let newY = newValue ? newValue + indent : lastY + indent
      if (newY > height - 15) {
        doc.addPage('a4', orientation)
        newY = 10
      }
      lastY = newY
      return newY
    }

    doc.setFont('Montserrat')

    doc.setFontSize('12').setFont(undefined, 'bold')
    doc.text('Отчет о поступлении выпускников довузовских общеобразовательных организаций', width/2, lastY, 'center')
    doc.text(`Министерства обороны Российской Федерации, выпустившихся в ${year} году`, width/2, getY(7), 'center')

    doc.autoTable({
      html: '#graduatesTable',
      styles: { font: 'Montserrat', fontSize: 9 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      columnStyles: {
        1: { halign: 'left' },
      },

      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(7),
    })

    return doc
  }

  const toPdf = () => {
    getPdfData().then(doc => doc.save(`${fileTitle}.pdf`))
  }
  
  const toExcel = () => {
    getXLSdata()
  }
  
  if (!visible) return null;

  return (
    <div className={styles.buttonsBlock}>
      <Button theme='success' className={'mb-2'} onClick={toExcel}>
        Скачать .xlsx
      </Button>
      &nbsp;
      <Button theme='danger' className={'mb-2'} onClick={toPdf}>
        Скачать .pdf
      </Button>
    </div>
)
}
