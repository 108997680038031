import { START, SUCCESS, ERROR } from '../../../constants/action-postfix'
import { call, put, takeEvery, select } from 'redux-saga/effects'
import { reportsAPI } from '../../../api/reports'
import { getPerfGroups, getPerfoptions, getPerfValues, setPerfValue } from '../../../api/newBestSchoolReport'
import { stopSubmit } from 'redux-form'
import { createActions } from '../../factory/actionFactory'
import { api } from '../../../api'
import { BASE_PARAMS } from '../../../constants/api'
import { formatDate } from '../../../utils/formatDate'
import {data, groups, criterions} from '../../../constants/bestSchoolPlug'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'



const getSpecparamsList = ({ login, pass }) => {
  const params = {
    ...BASE_PARAMS,
    action: `consolidated.list.specparams`,
    login,
    pass,
  }

  return api.get(`/`, { params })
}

const BEST_SCHOOL_REPORT_REQUEST = 'BEST_SCHOOL_REPORT_REQUEST'
const SPECPARAMS_LIST_REQUEST = 'SPECPARAMS_LIST_REQUEST'
const RATING_CRITERION_GROUPS_REQUEST = 'RATING_CRITERION_GROUPS_REQUEST'
const RATING_CRITERIONS_REQUEST = 'RATING_CRITERIONS_REQUEST'
const SET_RATING_VALUE_REQUEST = 'SET_RATING_VALUE_REQUEST'

// ACTIONS

export const bestSchoolActions = createActions('bestSchool', BEST_SCHOOL_REPORT_REQUEST)
export const specparamsActions = createActions('specparams', SPECPARAMS_LIST_REQUEST)
export const criterionGroupsActions = createActions('criterionGroups', RATING_CRITERION_GROUPS_REQUEST)
export const criterionsActions = createActions('criterions', RATING_CRITERIONS_REQUEST)
export const setRatingValueActions = createActions('setRatingValue', SET_RATING_VALUE_REQUEST)

const bestSchoolInitialState = {
  data: null,
  groups: null,
  criterions: null,
  isLoading: false,
  error: null,
}

const specparamsInitialState = []

export const bestSchoolReducer = (state = bestSchoolInitialState, action) => {
  switch (action.type) {
    
    case `${BEST_SCHOOL_REPORT_REQUEST}${START}`:
      return {
        ...state,
        isLoading: true,
      }
    
    case `${BEST_SCHOOL_REPORT_REQUEST}${SUCCESS}`:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      }

    case `${SET_RATING_VALUE_REQUEST}${ERROR}`:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case `${RATING_CRITERION_GROUPS_REQUEST}${SUCCESS}`:
      return {
        ...state,
        groups: action.payload,
      }

    case `${RATING_CRITERIONS_REQUEST}${SUCCESS}`:
      return {
        ...state,
        criterions: action.payload,
      }

    default:
      return state
  }
}

export const specparamsReducer = (state = specparamsInitialState, action) => {
  switch (action.type) {
    case `${SPECPARAMS_LIST_REQUEST}${SUCCESS}`:
      return action.payload

    default:
      return state
  }
}

// SAGAS

export function* bestSchoolWatcher() {
  yield takeEvery(`${BEST_SCHOOL_REPORT_REQUEST}${START}`, bestSchoolWorker)
  yield takeEvery(`${SPECPARAMS_LIST_REQUEST}${START}`, specparamsWorker)
  yield takeEvery(`${RATING_CRITERION_GROUPS_REQUEST}${START}`, ratingGroupsWorker)
  yield takeEvery(`${RATING_CRITERIONS_REQUEST}${START}`, ratingCriterionsWorker)
  yield takeEvery(`${SET_RATING_VALUE_REQUEST}${START}`, setRatingValueWorker)
}

function* bestSchoolWorker({ payload }) {
  const formData = yield select(({ reports }) => reports.bestSchoolForm)
  const year = moment(formData.date).format('YYYY')
  const specparamid = formData.specparamsId
  const authData = yield select(({ auth }) => auth.data)
  try {
    // yield put(criterionGroupsActions.criterionGroupsRequestStart())
    yield put(criterionsActions.criterionsRequestStart())
    const res = yield call(getPerfValues, authData, {year, specparamid})
    yield put(bestSchoolActions.bestSchoolRequestSuccess(res.data.Answer.Data))
  } catch (error) {
    yield put(bestSchoolActions.bestSchoolRequestError(error))
    const message = error || 'Возникла непредвиденная ошибка'
    yield put(stopSubmit('orglist', { _error: message }))
  }
}

function* specparamsWorker() {
  const authData = yield select(({ auth }) => auth.data)
  try {
    const response = yield call(getSpecparamsList, authData)
    yield put(specparamsActions.specparamsRequestSuccess(response.data.Answer.Data))
  } catch (error) {
    yield put(specparamsActions.specparamsRequestError(error))
  }
}

function* ratingGroupsWorker() {
  const authData = yield select(({ auth }) => auth.data)
  try {
    const response = yield call(getPerfGroups, authData)
    yield put(criterionGroupsActions.criterionGroupsRequestSuccess(response.data.Answer.Data))
  } catch (error) {
    yield put(criterionGroupsActions.criterionGroupsRequestError(error))
  }
}

function* ratingCriterionsWorker() {
  const authData = yield select(({ auth }) => auth.data)
  try {
    const response = yield call(getPerfoptions, authData)
    yield put(criterionsActions.criterionsRequestSuccess(response.data.Answer.Data))
  } catch (error) {
    yield put(criterionsActions.criterionsRequestError(error))
  }
}

function* setRatingValueWorker({ payload }) {
  const authData = yield select(({ auth }) => auth.data)
  const formData = yield select(({ reports }) => reports.bestSchoolForm)
  const year = moment(formData.date).format('YYYY')
  try {
    const response = yield call(setPerfValue, authData, payload, year)
    yield put(setRatingValueActions.setRatingValueRequestSuccess())
    if (response.data.Answer.Result === 'Error') {
      NotificationManager.error('Ошибка установки значения')
      yield put(setRatingValueActions.setRatingValueRequestError(payload))
    } else {
      NotificationManager.success('Значение успешно установлено')
    }
  } catch (error) {
    NotificationManager.error('Ошибка установки значения')
    yield put(setRatingValueActions.setRatingValueRequestError(payload))
  }
}
