import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import styles from './styles.module.css'
import { Table } from 'react-bootstrap'
import { setRatingValueActions } from '../../../../redux/reducers/BestSchool/bestSchoolReducer'
import cn from 'classnames'
import { Button } from "shards-react";
import { getExcelFromTable } from "../../../../utils/xlsx";
import { getPdfData } from "./pdf";

const mapStateToProps = ({reports}) => ({
  data: reports.bestSchool.data,
  groups: reports.bestSchool.groups,
  criterions: reports.bestSchool.criterions,
  error: reports.bestSchool.error
});

const mapDispatchToProps = {
  setRatingValue: setRatingValueActions.setRatingValueRequestStart
};

const BestSchoolDataTable = ({ data, groups, criterions, setRatingValue, error }) => {

  const [schoolsList] = useState(Array.from(new Set(data.map(({orgname}) => orgname))))

  // const renderGroup = ({name, id}) => {
  //   const actualCriterions = criterions.filter(({indicator_group}) => indicator_group === id)
  //   return (
  //   <>
  //     <tr>
  //       <td className={cn(styles.titleRow, styles.stickyCell)}>
  //         {name}
  //       </td>
  //       <td colSpan={schoolsList.length}></td>
  //     </tr>
  //     {actualCriterions.map(renderCriterions)}
  //   </>
  // )}


  // const renderCriterions = (criterion) => {
  //   const criterionData = data.filter(({indicator_id}) => indicator_id === criterion.id)
  //   return (
  //     <tr>
  //       <td className={cn(styles.stickyCell)}>
  //         {criterion.name}
  //       </td>
  //       {criterionData.map(value => <TableCell error={error} setRatingValue={setRatingValue} data={value}/>)}
  //     </tr>
  //   )
  // }

  const getXlsx = () => {
    const table = document.getElementById('bestSchoolTable');
    getExcelFromTable(table, 'bestSchoolTable');
  }

  const toPdf = () => {
    getPdfData().then(doc => doc.save(`bestSchoolTable.pdf`))
  }

  const renderCriterion = (groupsLevel) => (criterion, ind) => {
    const { name, Groups, Options } = criterion
    const criterionName = groupsLevel ? `${ind+1}. ${name}` : name
    const hasChildren = Groups || Options || groupsLevel
    const rowValues = !hasChildren && data.filter(({indicator_id}) => indicator_id === criterion.id)
    return (
      <>
        {hasChildren
          ? <tr>
              <td className={styles.stickyCell} style={{fontWeight: 'bold'}}>{criterionName}</td>
              <td colSpan={schoolsList.length}></td>
            </tr>
          : <tr>
              <td className={styles.stickyCell}>{criterionName}</td>
              {rowValues.map(value => <TableCell error={error} setRatingValue={setRatingValue} data={value}/>)}   
            </tr>
        }
        { Groups && Groups.map(renderCriterion())}
        { Options && Options.map(renderCriterion())}
      </>
    )
  }

  return (
    <>
      <div className={styles.buttonsBlock}>
        <Button
          theme="success"
          onClick={getXlsx}
        >
          Скачать .xlsx
        </Button>
        
        <Button
          theme="danger"
          className={styles.xlsxButton}
          onClick={toPdf}
        >
          Скачать pdf
        </Button>
      </div>
        <Table id='bestSchoolTable'>
          <thead>
            <tr>
              <th className={cn(styles.titleRow, styles.stickyCell)}>
                Критерий
              </th>
              {schoolsList.map(school =>
                <th className="">{school}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {criterions && criterions.map(renderCriterion('groupsLevel'))}
          </tbody>
        </Table>
    </>
  );
};


const TableCell = ({data, setRatingValue, error}) => {
  
  const [value, setValue] = useState(data.value)
  
  useEffect(() => {
    if (!error) return;
    const { idOrganization, indicator_id, value } = data
    const { idOrg, indicator } = error
    if (idOrg === idOrganization && indicator === indicator_id) {
      setValue(value)
    }
  }, [error])
  

  const onCellBlur = (e) => {
    const { idOrganization, indicator_id } = data
    const newValue = e.target.innerText
    if (value == newValue) return; 
    const payload = {
      value: newValue,
      indicator: indicator_id,
      idOrg: idOrganization
    }
    setRatingValue(payload)
    setValue(newValue)
  }

  return (
    <td
      contentEditable
      onBlur={onCellBlur}
      onKeyPress={(event) => !/[.0-9]/.test(event.key) && event.preventDefault()}
    >
      {value}
    </td>
  )
}







export default connect( mapStateToProps, mapDispatchToProps )(BestSchoolDataTable);
